import React from "react";
import styled from "styled-components";
import {Accordion, Card} from "react-bootstrap";

const TechStackSection = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  transition: flex 0.3s ease-out
  overflow: hidden;
  min-height: 50vh;
  justify-content: space-evenly;  
`;

const TechStackContainer = styled.div` 
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;  
  `;
const TechStackTitle = styled.h3``;
const TechnologyName = styled.p``;
const TechStackLink = styled.a``;
const TechStackImage = styled.img`
    width: auto;
    height: 64px;
`;

const TechStackHover = styled.div`
  cursor: pointer;
  transition: font-size .5s ease;
  &:hover {
    font-size: 1.2em;
  }
`

const TechnologyContainer = styled.div`
  width: 150px;
`;

const EmptySpace = styled.div`
  margin-bottom: 50px;
`

const Technology = ({name, imgSrc, imgAlt, href}) => {
  return (
      <TechnologyContainer>
        <TechStackLink href={href}>
          <TechStackImage src={imgSrc} alt={imgAlt}/>
        </TechStackLink>
        <TechnologyName>
          {name}
        </TechnologyName>
      </TechnologyContainer>
  );
}

const StackInfo = ({sectionName, items}) => {
  return (
      <div>
        {/*  section title*/}
        <TechStackTitle>{sectionName}</TechStackTitle>
        {/*TechStackSectionItems*/}
        <TechStackContainer>
          {items.map(item => (
              <Technology
                  name={item.name}
                  imgSrc={item.imgSrc}
                  imgAlt={item.imgAlt}
                  href={item.href}
              />
          ))}
        </TechStackContainer>
      </div>
  )

}
const AccordionCustom = styled(Accordion)`
    transition: box-shadow 0.5s;    
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
    margin: 0 10px 0 10px
`
const TechStack = () => {

  return (
      <React.Fragment>
        <EmptySpace/>

        <AccordionCustom>
          <Card>
            <AccordionCustom.Toggle as={Card.Header} eventKey="0">
              <TechStackHover>
                Check our stack
              </TechStackHover>
            </AccordionCustom.Toggle>
            <AccordionCustom.Collapse eventKey={"0"}>
              <Card.Body>
                <TechStackSection>
                  {testData.map(value => (
                      <StackInfo
                          sectionName={value.sectionName}
                          items={value.sectionItems}
                      />
                  ))}
                </TechStackSection>
              </Card.Body>
            </AccordionCustom.Collapse>
          </Card>
        </AccordionCustom>
        <EmptySpace/>
      </React.Fragment>
  );
}



export default TechStack;
const testData = [
  {
    sectionName: "Backend",
    sectionItems: [
      {
        "name": "Java",
        "imgSrc": "/images/logo/tools/java.png",
        "imgAlt": "java logo",
        "href": "#"
      },
      {
        "name": "Kotlin",
        "imgSrc": "/images/logo/tools/kotlin.png",
        "imgAlt": "kotlin logo",
        "href": "#"
      },
      {
        "name": "SpringBoot",
        "imgSrc": "/images/logo/tools/spring-boot-logo.png",
        "imgAlt": "spring boot logo",
        "href": "#"
      },
      {
        "name": ".Net Core",
        "imgSrc": "/images/logo/tools/net_core.png",
        "imgAlt": ".net core logo",
        "href": "#"
      },
      {
        "name": "Python",
        "imgSrc": "/images/logo/tools/python.png",
        "imgAlt": "python logo",
        "href": "#"
      },
      {
        "name": "NodeJs",
        "imgSrc": "/images/logo/tools/node.png",
        "imgAlt": "node js logo",
        "href": "#"
      },
      {
        "name": "Golang",
        "imgSrc": "/images/logo/tools/golang.png",
        "imgAlt": "golang logo",
        "href": "#"
      },
    ]
  },
  {
    sectionName: "Frontend",
    sectionItems: [
      {
        "name": "React",
        "imgSrc": "/images/logo/tools/react-logo.png",
        "imgAlt": "react logo",
        "href": "#"
      },
      {
        "name": "Angular",
        "imgSrc": "/images/logo/tools/angular.png",
        "imgAlt": "angular logo",
        "href": "#"
      },
      {
        "name": "Vue",
        "imgSrc": "/images/logo/tools/vue.png",
        "imgAlt": "vue logo",
        "href": "#"
      }
    ]
  },
  {
    sectionName: "Tools",
    sectionItems: [
      {
        "name": "Docker",
        "imgSrc": "/images/logo/tools/docker.png",
        "imgAlt": "docker logo",
        "href": "#"
      },
      {
        "name": "GCP",
        "imgSrc": "/images/logo/tools/gcp.png",
        "imgAlt": "",
        "href": "#"
      },
      {
        "name": "Terraform",
        "imgSrc": "/images/logo/tools/terraform.png",
        "imgAlt": "logo",
        "href": "#"
      },
      {
        "name": "Ansible",
        "imgSrc": "/images/logo/tools/ansible.png",
        "imgAlt": "ansible logo",
        "href": "#"
      },
      {
        "name": "Jenkins",
        "imgSrc": "/images/logo/tools/jenkins.png",
        "imgAlt": "jenkins logo",
        "href": "#"
      },
      {
        "name": "Octopus Deploy",
        "imgSrc": "/images/logo/tools/octopus.png",
        "imgAlt": "octopus logo",
        "href": "#"
      },
      {
        "name": "Kubernetes",
        "imgSrc": "/images/logo/tools/kubernetes.png",
        "imgAlt": "kubernetes logo",
        "href": "#"
      }
    ]
  },
]